#app-title {
  font-size: 1.5em;
  line-height: 1.75em;
  width: 100%;
  font-weight: 700;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

#title-container {
  margin: 0;
}

#app-container {
  width: 95%;
  margin: 0 auto;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  #app-title {
    font-size: 2em;
    line-height: 2.25em;
  }

  #app-container {
    width: 80%;
  }
}

/* Large devices (laptops, 992px and up) */
@media (min-width: 992px) {
  #app-container {
    width: 70%;
  }
}

/* Extra large devices (desktops, 1490px and up) */
@media (min-width: 1490px) {
  #app-container {
    width: 60%;
  }
}
