.app-container {
	background-color: #ffffff;
	border-radius: 5px;
	width: 65%;
	height: 80vh;
	display: flex;
	flex-direction: column;
	padding-left: 3em;
	padding-right: 3em;
}

.order-container {
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
}

.order-container .order:nth-child(odd) {
	background-color: #f1f3f25c;
}

.details-container {
	background-color: #ffffff;
	border-radius: 5px;
	width: 65%;
	display: flex;
	flex-direction: column;
	padding-left: 3em;
	padding-right: 3em;
	padding-bottom: 1em;
	margin: auto;
}

.details-container hr {
	color: #dcdcdc;
	height: 1px;
	width: 100%;
}

.details-header {
	width: 100%;
	margin-bottom: 0.2em;
}

.details-header::after {
	background: #dcdcdc;
	content: "";
	height: 1px;
	width: 100%;
	display: block;
}
