.error-container {
    align-items: center;
	padding-top: 2em;
	padding-bottom: 2em;
}

.heading {
	margin-left: auto;
	margin-right: auto;
	margin-top: 0;
	margin-bottom: 0;
}

#lottie-container {
		display: "flex";
		align-items: "center";
		margin-top: "5%";
		margin-bottom: "5%";
		padding: "0.5%";
		border-radius: "8px";
		height: "inherit";
		width: "inherit";
	}
