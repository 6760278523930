:root {
  --primary-color: #4d46d9;
  --white: #ffffff;
  --deep-blue: #1c2533;
  --steel-grey: #8493a8;
  --cyan: #22b9da;
  --orange: #f88d2a;
  --text-dark: #282828;
  --text-mid: #707070;
  --warning-color: #e81d2d;
  --background-color: #edecfb;
  --success-color: #00774a;
  font-family: 'Martel Sans', Arial, Helvetica, sans-serif;
  color: var(--text-dark);
}

a {
  color: var(--text-dark);
  text-decoration: underline;
  text-decoration-color: var(--primary-color);
}

h3 {
  font-size: 1.25em;
  line-height: 1.5em;
  font-weight: 700;
}

ul {
  padding-left: 0;
}

.details-check {
  line-height: 1.75em;
}

.component-container {
  width: calc(100% - 4em);
  background-color: #ffffff;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0.7em;
  padding-bottom: 1em;
}

.component-container.subject-select {
  padding-bottom: 2em;
}

.student-degree-container {
  flex-direction: row;
  justify-content: space-between;
}

.student-details-container {
  padding-top: 2em;
  padding-bottom: 2em;
  position: relative;
}

.mortar-image {
  display: none;
}

.details-check {
  font-size: 1em;
  line-height: 1.4em;
}

.student-info {
  margin-left: auto;
  margin-right: auto;
  padding: 2em;
  text-align: center;
  margin-bottom: 0.5em;
  position: relative;
  width: fit-content;
  font-size: 1.25em;
}

span.heading {
  font-weight: 700;
  font-family: 'Martel', Arial, sans-serif;
}

.subject-options {
  display: flex;
  font-size: 18px;
  flex-direction: column;
  justify-content: flex-start;
}

.subject-options .checkbox-wrapper {
  justify-content: flex-start;
  font-size: 0.85em;
  line-height: 1.5em;
}
.module-info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1em;
  line-height: 2em;
}

.checkbox-span {
  align-self: flex-end;
}

li {
  list-style: none;
}

li svg {
  margin-right: 0.5em;
}

p.degree-requirements {
  line-height: 1.5rem;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .component-container {
    width: calc(100% - 8em);
    padding-left: 4em;
    padding-right: 4em;
  }

  .student-info {
    width: 50%;
  }

  .mortar-image {
    display: block;
  }

  h3 {
    font-size: 1.25em;
    line-height: 1.5em;
    font-weight: 700;
  }

  .details-check {
    line-height: 1.75em;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .component-container {
    padding-left: 4em;
    padding-right: 4em;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .student-info {
    width: fit-content;
  }
}
