.select-and-button {
  display: flex;
  margin-right: auto;
  flex-direction: row;
  align-items: flex-start;
}

.degree-image {
  display: none;
}

.degree-text {
  max-width: 100%;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .degree-text {
    max-width: 80%;
  }

  .degree-image {
  display: block;
  }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}
